'use strict';

import $ from 'jquery';
import Header from '../header/header';

export default class Navigation {
  constructor() {
    this._onClick();
    this.naviOpen = false;
    this.$header = $('.main-header');
  }

  _onClick() {
    $('.navi-button, .quick-contact').on('click', (e) => {
      e.preventDefault();
      let $e = $(e.currentTarget);
      let target = $e.data('target');
      if (this.naviOpen) {
        if (!$e.hasClass('is-active')) {
          return;
        }
        // Close navi
        $e.removeClass('is-active');
        this.$header.removeClass('is-navi-opened');
        this.naviOpen = false;
        $(`.${target}`).removeClass('is-visible');
        $('body').css({'overflow' : ''});
      } else {
        // Open navi
        this.naviOpen = true;
        $e.addClass('is-active');
        Header._setHeaderPosition(0);
        this.$header.addClass('is-navi-opened');
        $(`.${target}`).addClass('is-visible');
        $('body').css({'overflow' : 'hidden'});
      }

    });
  }
}
