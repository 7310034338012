'use strict';

import Masonry from 'masonry-layout';
import ImagesLoaded from 'imagesloaded';
import $ from 'jquery';
import Overlay from './overlay';

export default class Filter {
  constructor() {
    let $selector = $('[data-filter-slug]');

    if ($selector.length > 0) {
      $selector.on('click', (e) => {
        e.preventDefault();
        let $this = $(e.currentTarget);

        let slug = $this.data('filter-slug');
        let name = $this.data('filter-name');
        let id = $this.data('filter-id');
        console.log(slug);
        Overlay.openLoader(this.filter(slug, name, id));
      })
    }
  }

  filter(slug, name, id) {
    console.log(slug, name, id);
    $('.teaser-container, .slogan-container').addClass('is-hidden');
    $('.ajax-container').addClass('is-visible');
    Overlay.closeLoader();
  }

}