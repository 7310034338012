'use strict';

import $ from 'jquery';

export default class Overlay {

  static openLoader(callback) {
    const $loader = $('.loader');
    $loader.addClass('is-visible');
    if (typeof callback === 'function') {
      setTimeout(callback, 601);
    }
  }

  static closeLoader(callback) {
    const $loader = $('.loader');
    $loader.removeClass('is-visible');
    if (typeof callback === 'function') {
      setTimeout(callback, 601);
    }
  }

}