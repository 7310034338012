// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Header from '../_modules/header/header';
import Slogan from '../_modules/slogan/slogan';
import Navigation from '../_modules/navigation/navigation';
import Teaser from '../_modules/teaser/teaser';
import ParallaxHeader from '../_modules/parallax-header/parallax-header';
import SingleWorks from '../_modules/single-works/single-works';
import RelatedContent from '../_modules/related-content/related-content';

import Filter from './filter';

$(() => {

  if('CSS' in window && 'supports' in window.CSS) {

    const support = window.CSS.supports('mix-blend-mode','multiply');

    /* Add class like Modernizr */
    /* -- jQuery -- */
    $('html').addClass(support?'mix-blend-mode':'no-mix-blend-mode'); // jQuery
  }

  new Header();
  new Navigation();
  new Slogan();
  new Teaser();
  new ParallaxHeader();
  new SingleWorks();
  new RelatedContent();

  new Filter();

});
