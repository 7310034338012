'use strict';

import Masonry from 'masonry-layout';
import ImagesLoaded from 'imagesloaded';
import $ from 'jquery';

export default class RelatedContent {
  constructor() {
    const container = document.querySelector('.related-content--container');
    if ($('.related-content--container').length > 0) {
      this._imagesLoaded(container);
    }
  }

  _imagesLoaded(wrapper) {
    return new ImagesLoaded(wrapper, () => {
      setTimeout(() => {
        this.masonry(wrapper);
      }, 500);
    });
  }

  masonry(elem) {
    return new Masonry(elem, {
      itemSelector: '.teaser',
      columnWidth: 1,
      gutter: 67,
      isResizable: true
    });
  }
}
