'use strict';
import $ from 'jquery';

export default class Slogan {
  constructor() {
    if ($('.slogan').length > 0) {
      this._onResize();
      $(window).trigger('resize');
    }
  }

  _onResize() {
    $('.slogan-container').height($('.slogan').height());
  }
}
