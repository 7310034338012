'use strict';

import $ from 'jquery';

export default class Header {
  constructor() {
    this.$header = $('.main-header');
    this.max = this.$header.height();
    this.lastAmount = 0;
    this.headerCompact = false;

    this._onScroll();
  }

  _onScroll() {
    $(window).on('scroll', (event) => {
      if (!$('.main-header').hasClass('is-navi-opened')) {
        let amount = $(window).scrollTop();

        if (amount == 0) {
          this._removeHeaderCompact();
        }

        if (amount > this.lastAmount) {
          // Scroll Down
          if (!this.headerCompact) {
            this.constructor._setHeaderPosition(-amount);
          } else {
            this.constructor._setHeaderPosition(-this.$header.height());
          }
          if (amount > this.max) {
            this._setHeaderCompact();
          }
        } else {
          // Scroll Up
          if (!this.headerCompact) {
            this.constructor._setHeaderPosition(-amount);
          } else {
            this.constructor._setHeaderPosition(0);
          }
        }
        this.lastAmount = amount;
      }
    });
  }

  static _setHeaderPosition(pos) {
    $('.main-header').css({'transform': `translateY(${pos}px)`});
  }

  _setHeaderCompact() {
    if (!this.headerCompact) {
      this.headerCompact = true;
      this.$header.addClass('compact');
    }
  }

  _removeHeaderCompact() {
    if (this.headerCompact) {
      this.headerCompact = false;
      this.$header.removeClass('compact');
    }
  }


}
