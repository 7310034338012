'use strict';

import $ from 'jquery';
import Parallax from 'scroll-parallax';

export default class ParallaxHeader {
  constructor() {
    if ($('.parallax-container').length > 0) {
      this._setContainerHeight();
      this._onResize();
      this.parallax = new Parallax('.parallax-img', {
        intensity: 50
      }).init()
    }
  }

  _onResize() {
    $(window).on('resize', () => {
      this._setContainerHeight();
      this.parallax.refresh();
    });
  }

  _setContainerHeight() {
    let h = document.documentElement.clientHeight;
    $('.parallax-container').height(h * 0.7);
  }
}
