'use strict';

import Masonry from 'masonry-layout';
import ImagesLoaded from 'imagesloaded';
import $ from 'jquery';

export default class SingleWorks {
  constructor() {
    const container = document.querySelector('.single-work--container');
    if ($('.single-work--container').length > 0) {
      this._imagesLoaded(container);
    }
  }

  _imagesLoaded(wrapper) {
    return new ImagesLoaded(wrapper, () => {
      setTimeout(() => {
        this.masonry(wrapper);
      }, 500);
    });
  }

  masonry(elem) {
    return new Masonry(elem, {
      itemSelector: '.single-work--container article',
      columnWidth: 1,
      gutter: 54,
      isResizable: true
    });
  }
}
